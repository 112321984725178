<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 会员信息
 * @Date: 2020-12-27 16:27:31
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-06 10:46:23
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/perinfo.vue
-->
<template>
  <div class="user_content">
    <van-cell-group>
      <van-cell title="头像">
        <template #default>
          <div class="head">
            <img :src="avatar" alt="">
          </div>
        </template>
      </van-cell>
      <van-cell title="姓名" is-link :value="name" @click="changeName(name)" />
      <van-cell title="昵称" is-link :value="nickname" @click="goNickname(nickname)" />
      <van-cell title="性别" is-link :value="gender" @click="Gender" />
      <van-cell title="手机号" :value="mobile" />
    </van-cell-group>
    <div class="h10" />
    <van-cell-group>
      <van-cell title="国家" :value="country" />
      <van-cell title="地址管理" is-link to="/addressList?type=1" />
      <van-cell title="证件类型" :value="certifiType | state" />
      <van-cell title="证件号" is-link :value="cardNo" @click="certificate(cardNo)" />
      <van-cell title="绑定邮箱" is-link :value="email" @click="upemail(email)" />
      <van-cell title="修改登录密码" is-link @click="cloginpas(mobile)" />
    </van-cell-group>
    <div class="h10" />
    <van-cell-group>
      <van-cell title="实名认证" is-link to="/Infoauthentication" @click="Realname" />
      <van-cell title="客服电话" is-link @click="customer('4001109600')" />
      <van-cell title="退出登录" is-link @click="Logout" />
    </van-cell-group>
    <div class="agreement">
      <router-link tag="span" to="/Policy">服务协议</router-link>
      <span style="color:#999999;"> | </span>
      <router-link tag="span" to="/Policy">隐私政策</router-link>
    </div>
    <van-popup v-model="show" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Cell, CellGroup, Icon, Picker, Popup, Toast } from 'vant'

Vue.use(Icon).use(Cell).use(CellGroup).use(Toast).use(Popup).use(Picker)
import {
  memberinfo,
  upGender
} from '@/services/userApi'

export default {
  filters: {
    state(v) {
      const map = {
        1: '身份证',
        2: '签证',
        3: '护照',
        4: '户口本',
        5: '军人证',
        6: '港澳通行证',
        7: '居住证'
      }
      return map[v]
    }
  },
  data() {
    return {
      show: false,
      avatar: '',
      name: '',
      nickname: '',
      gender: '',
      mobile: '',
      country: '',
      certifiType: '',
      cardNo: '',
      email: '',
      columns: [
        { value: 0, text: '保密' },
        { value: 1, text: '男' },
        { value: 2, text: '女' }
      ]
    }
  },
  created() {
    this.getuserinfo()
  },
  methods: {
    getuserinfo() {
      memberinfo().then(res => {
        if (Number(res.code) === 200) {
          this.avatar = res.avatar
          this.name = res.name
          this.nickname = res.nickname
          this.gender = res.gender === 1 ? '男' : res.gender === 2 ? '女' : '保密'
          this.mobile = res.mobile
          this.country = res.country
          this.certifiType = res.certifiType
          this.cardNo = res.cardNo
          this.email = res.email
        }
      })
    },
    // 退出登录
    Logout() {
      this.$store.dispatch('user/logout', null).then(res => {
        Toast('退出成功')
        this.$router.push('/')
      })
    },
    customer(tel) {
      window.location.href = 'tel:' + tel
    },
    // 修改性别
    Gender() {
      this.show = true
    },
    onCancel() {
      this.show = false
    },
    onConfirm(value, index) {
      const parm = {
        gender: value.value
      }
      upGender(parm).then(res => {
        if (Number(res.code) === 200) {
          this.getuserinfo()
          this.show = false
          Toast(res.msg)
        } else {
          Toast(res.msg)
        }
      })
    },
    // 实名认证
    Realname() {

    },
    changeName(n) {
      this.$router.push({ path: '/Changename', query: { 'name': n }})
    },
    goNickname(n) {
      this.$router.push({ path: '/nickname', query: { 'name': n }})
    },
    certificate(n) {
      this.$router.push({ path: '/Certificate', query: { 'cardNo': n }})
    },
    cloginpas(h) {
      this.$router.push({ path: '/Cloginpas', query: { 'mobile': h }})
    },
    upemail(n) {
      this.$router.push({ path: '/upemail', query: { 'email': n }})
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .head {
    width: 35px;
    height: 35px;
    background: #BFBFBF;
    border-radius: 50%;
    overflow: hidden;
    float: right;
  }

  .h10 {
    height: 10px;
  }

  .agreement {
    padding-top: 50px;
    color: #E33F44;
  }
}

.van-cell__title {
  flex: 4;
  text-align: left;
}

.van-cell__value {
  flex: 6;
}

.van-cell {
  align-items: center;
}
</style>
